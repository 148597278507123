<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="800px"
    :title="editData.id ? '修改税率库信息' : '新增税率库信息'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    append-to-body
  >
    <el-form ref="form" class="form" :model="form" :rules="rules" label-position="top">
      <div class="row">
        <el-form-item label="美国税号" prop="hsCode">
          <el-input v-model="form.hsCode" oninput="value=value.replace(/[^\d]/g,'')" style="width: 320px"></el-input>
        </el-form-item>
        <el-form-item label="基础税率" prop="baseRate">
          <el-input
            v-model="form.baseRate"
            style="width: 320px"
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="加征税率" prop="addRate">
          <el-input
            v-model="form.addRate"
            style="width: 320px"
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="批次号" prop="batchNo">
          <el-input
            v-model="form.batchNo"
            style="width: 320px"
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="中文描述" prop="desCH">
          <el-input v-model="form.desCH" style="width: 672px"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="英文描述" prop="desEN">
          <el-input v-model="form.desEN" style="width: 672px"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" style="width: 672px"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定{{ editData.id ? '修改' : '新增' }}</div>
    </span>
  </el-dialog>
</template>
<script>
import { saveTaxRate } from '@/api/basic'
import { mapGetters } from 'vuex'
export default {
    components: { },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0 // 0 新增 1修改
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        hsCode: '',
        remark: '',
        baseRate: '',
        addRate: '',
        batchNo: '',
        desCH: '',
        desEN: ''
      },
      rules: {
        hsCode: [
          { required: true, message: '请输入美国税号', trigger: 'blur' }
        ],
        baseRate: [
          { required: true, message: '请输入基础税率', trigger: 'blur' }
        ],
        addRate: [
          { required: true, message: '请输入加征税率', trigger: 'blur' }
        ]
      },
      dialogVisible2: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'companyInfo'])
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.editData.id) {
          this.form = {
            ...this.editData
          }
        } else {
          this.form = {
            hsCode: '',
            remark: '',
            baseRate: '',
            addRate: '',
            batchNo: '',
            desCH: '',
            desEN: ''
          }
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            ...this.form,
            createdName: this.userInfo.userName,
            companyId: this.companyInfo?.id
          }
          try {
            const res = await saveTaxRate(params)
            if (res.success) {
              this.$message.success('操作成功')
              this.$refs[formName]?.resetFields()
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
          console.log(params, saveTaxRate)
        }
      })
    },
    add() {
      this.dialogVisible1 = true
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.el-dialog__body) {
  padding: 30px 10px;
}
.form {
    margin: 0 54px;
}
.row {
  display: flex;
  justify-content: space-between;
  &:deep(.el-form-item) {
    margin-bottom: 16px;
    .el-form-item__label {
      line-height: 20px;
    }
  }
  .icon {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: #2395F3;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
